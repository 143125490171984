
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Helmet from 'react-helmet';
import background from '../../../../static/img/services/accountability.jpg';
import ContactBox from '../../../components/ContactBox.js';
import Hero from '../../../components/Hero.js';
import Navbar from '../../../components/Navbar';
import DefaultLayout from '../../../layouts/Default';
import '../../../sass/pages/services/contable.scss';
import LogoSalary from '../../../../static/img/logosalary.svg';

const links = [
  { label: (<><FontAwesomeIcon icon={['fas', 'arrow-left']} /> VOLVER</>), href: "/sections/contable" },
  { label: "CONTACTAR", href: "/services/contable/sueldos#contact-box"}
];

const hero = {
  title: 'Liquidación de Sueldos',
  /*description: 'Basado en un sistema en la nube ,nuestro servicio de liquidación de sueldos abarca todo lo necesario para que no tengas que preocuparte por esta tarea.',*/
  background
};

const Detail = ({text}) => (
  <div className="detail">
    <FontAwesomeIcon className="detail-icon" icon={['fas', 'check-double']} />
    <span>{ text }</span>
  </div>
);


export default () => (
  <DefaultLayout>
    <Helmet>
      <title>VITECH - Rendiciones de Cuentas Sistema SIRC</title>
    </Helmet>

    <Navbar kind="secondary" links={ links } />

    <Hero {...hero } />

    <br /><br />

    <section id="contable">
      <div className="container-min">
        <header id="contable-header">
        </header>

        <section id="contable-details" className="detail-list">
          <Detail text="Liquidación de sueldos: mensual, jornalero." />
          <Detail text="Generación de archivos de nómina para su ingreso a BPS, rectificativas y deducciones." />
          <Detail text="Liquidación de licencias, aguinaldos, despidos, retroactividades, retenciones, vales, adelantos y comisiones." />
          <Detail text="Generación del listado de imputación para pago por medios electrónicos a las distintas instituciones financieras." />
          <Detail text="Cálculo del IRPF para anticipos y ajuste anual." />
          <Detail text="Emisión de recibos de sueldo digitales y posibilidad de consulta en la web de las liquidaciones mensuales mediante ingreso con usuario registrado" />
          {/*<Detail text="Importación desde planilla Excel hacia las liquidaciones de GNS Personal de conceptos como adelantos, horas extras, faltas, llegadas tarde, etc." />*/}
        </section>
      </div>

      <div className="container-min pad-40">
        <div className="pad-40 shadow text-center gosueldos">
            <img src={ LogoSalary } alt="Logo VITECH Sueldos" width="140" />
            <a href="https://sueldos.vitech.uy" rel="noopener noreferrer" target="_blank" className="button button-inverse">
              Acceder
            </a>
        </div>
      </div>

      <div className="container-min">
        <ContactBox />
      </div>

    </section>
  </DefaultLayout>
);